import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SearchEngineOptimization
        title="Request Rates | Accel Mortgage | Home Loans"
        description="Requesting rates from Accel Mortgage is easy. Fill out our form and we'll point you in the direction of the financing and guidance you need."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="z-10 flex w-full items-center justify-between bg-[#EEF8FD] px-4 pt-8 md:absolute md:bg-transparent md:px-10">
          <Link to="/">
            <img
              src={data.logo.publicURL}
              alt="Accel Mortgage Logo"
              width={230}
            />
          </Link>

          <Link to="/" className="group no-underline">
            <i className="far fa-times text-2xl text-primary-900 transition-all duration-300 ease-linear group-hover:text-primary-400 md:text-white z-50"></i>
          </Link>
        </div>

        <div className="relative grid text-center md:h-screen md:grid-cols-2">
          <div className="absolute right-0 z-10 hidden md:block">
            <img src={data.line.publicURL} className="mx-auto" />
          </div>

          <div className="absolute inset-x-0 bottom-0 z-10 hidden md:block">
            <img src={data.decorative.publicURL} className="mx-auto" />
          </div>

          <div className="relative flex items-center justify-center py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div className="absolute inset-0 h-full w-full">
              <StaticImage
                src="../images/19.0 multistep/Purchase background desktop.jpg"
                loading="eager"
                className="h-full"
              />
            </div>
            <div className="relative z-30 mx-auto max-w-[536px]">
              <div className="mb-4 font-display text-sm font-light uppercase tracking-wide text-primary-900">
                I want to
              </div>
              <div className="mb-4 font-heading text-mobile-6xl text-primary-900 md:text-6xl">
                Purchase a Home
              </div>
              <p className="mb-8 font-display text-xl font-light tracking-wide">
                Thinking of buying a home? Answer a few easy questions, and
                we’ll get in touch with you shortly
              </p>
              <ButtonSolid href="/purchase/" text="Purchase" />
            </div>
          </div>

          <div className="relative flex items-center justify-center py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div className="absolute inset-0 h-full w-full">
              <StaticImage
                src="../images/19.0 multistep/Refinance background desktop.jpg"
                loading="lazy"
                className="h-full"
              />
            </div>
            <div className="relative z-30 mx-auto max-w-[536px]">
              <div className="mb-4 font-display text-sm font-light uppercase tracking-wide text-primary-100">
                I want to
              </div>
              <div className="mb-4 font-heading text-mobile-6xl text-white md:text-6xl">
                Refinance my Home
              </div>
              <p className="mb-8 font-display text-xl font-light tracking-wide text-primary-50">
                Thinking of buying a home? Answer a few easy questions, and
                we’ll get in touch with you shortly
              </p>
              <ButtonSolid href="/refinance/" text="Refinance" altStyle={2} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    logo: file(relativePath: { eq: "Global/Nav/Logo.svg" }) {
      publicURL
    }
    line: file(relativePath: { eq: "19.0 multistep/line.svg" }) {
      publicURL
    }
    decorative: file(relativePath: { eq: "19.0 multistep/decorative.svg" }) {
      publicURL
    }
  }
`;
export default Page;
